@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/English/Inter-Regular-slnt=0.ttf) format('truetype');
}

/* Transaction styles */
.transactionItemContainer {
  display: grid;
  text-align: center;
  border-bottom: thin solid #f0f0f0;
}

.transactionViewContainer {
  display: grid;
  text-align: center;
  border-bottom: thin solid #f0f0f0;
  grid-template-columns: 50px 20% 20% 150px 150px 1fr;
}

.transactionItemContainer > div,
.transactionViewContainer > div {
  padding: 15px 0;
  align-self: center;
}

.transactionContainer {
  margin-bottom: 20px;
}

.transactionInput {
  position: relative;
  padding: 4px 6px;
  border-radius: 3px;
  border: thin solid #d9d9d9;
}

.transactionInputHeader {
  padding: 0px 0 6px 6px;
}

/* //////////////////////////////////////// */
